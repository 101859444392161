import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>This is Privacy Policy Page</h1>
    </div>
  );
};

export default PrivacyPolicy;
