import React from "react";
import Slider from "./Slider";

const Hero = () => {
  return (
    <div>
      <Slider />
    </div>
  );
};

export default Hero;
